<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>名师风采</el-breadcrumb-item>
            <el-breadcrumb-item>名师执教</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row :gutter="20">
                <el-col :span="6">
                    <el-button type="primary" @click="showAdd">添加教师</el-button>
                </el-col>
            </el-row>
            <el-table :data="tableData" stripe border>
                <el-table-column type="index" label="序号" width="60px"></el-table-column>
                <el-table-column prop="url" label="封面照片">
                    <template slot-scope="scope">
                        <div style="height: 50px;">
                            <img alt="" :src="scope.row.cover" style="height: 100%"/>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="姓名"></el-table-column>
                <el-table-column prop="jobTitle" label="职称"></el-table-column>
                <el-table-column prop="subject" label="学科"></el-table-column>
                <el-table-column prop="sequence" label="顺序"></el-table-column>
                <el-table-column prop="createTime" label="创建时间"></el-table-column>
                <el-table-column prop="updateTime" label="更新时间"></el-table-column>
                <el-table-column prop="" label="操作">
                    <template slot-scope="scope">
                        <el-button type="primary" size="mini" @click="editItems(scope.row)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="removeItem(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 添加/修改教师 -->
            <el-dialog :title="isEdit ? '编辑教师' : '添加教师' " width="1150px" :visible.sync="dialogTeacher" :close-on-click-modal="false" @close="closedialogTeacher">
                <div class="dialog-content" style="max-height: 580px;overflow-y: auto;" v-if="dialogTeacher">
                    <div class="flex-warp mg15">
                        <span class="text_label"><i class="red">*</i>姓名：</span>
                        <el-input v-model="form.name" placeholder="请输入" style="flex:1"></el-input>
                    </div>
                    <div class="flex-warp mg15" style="align-items:flex-start">
                        <span class="text_label"><i class="red">*</i>封面照片：</span>
                        <el-upload ref="uploadEle" class="avatar-uploader" action="#" :show-file-list="false"
                                   :on-change="handleChange" :auto-upload="false" :multiple="false">
                            <img v-if="portrait" :src="portrait" class="avatar">
                            <i v-else class="el-icon-plus">
                                <div class="tip-box">
                                    <div>限制320px*425px大小</div>
                                    <div>支持png、jpg、jpeg格式图片</div>
                                </div>
                            </i>
                        </el-upload>
                    </div>
                    <div class="flex-warp mg15">
                        <span class="text_label"><i class="red">*</i>职称：</span>
                        <el-input  v-model="form.jobTitle" placeholder="请输入"style="flex:1"></el-input>
                    </div>
                    <div class="flex-warp mg15">
                        <span class="text_label"><i class="red">*</i>学科：</span>
                        <el-input  v-model="form.subject" placeholder="请输入"style="flex:1"></el-input>
                    </div>
                    <div class="flex-warp mg15">
                        <span class="text_label"><i class="red">*</i>简介：</span>
                        <!--            <el-input  type="textarea" v-model="form.introduction" placeholder="请输入" maxlength="2000" show-word-limit style="flex:1"-->
                        <!--                       :rows="4"></el-input>-->
                        <div style="width: 100%;height: 342px;">
<!--                            <quilleditor-widget ref="quillView" :details="form.introduction"></quilleditor-widget>-->
                            <ueditor-wrap v-if="finish" :details="form.introduction" ref="quillView" ></ueditor-wrap>
                        </div>
                    </div>
                    <div class="flex-warp mg15">
                        <span class="text_label"><i class="red">*</i>显示顺序：</span>
                        <el-input  v-model="form.sequence" placeholder="请输入" style="flex:1"></el-input>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button  @click="dialogTeacher = false">取 消</el-button>
                    <el-button  type="primary" @click="submitForm()" :loading="btnFormLoading">保 存</el-button>
                </span>
            </el-dialog>
        </el-card>
    </div>
</template>
<script>
import QuilleditorWidget from '../common/quill_editor_widget.vue';
import UeditorWrap from '../common/ueditor_wrap.vue';
export default {
    name: 'Teachers',
    data() {
        return {
            queryInfo: {
                currentPage: 1,//当前页码
                pageSize: 10,//每页显示多少条
            },
            totalCount: 0,
            tableData: [],
            dialogTeacher:false,//添加编辑教研成果弹窗
            isEdit:false,//true编辑 false添加
            btnFormLoading:false,//保存按钮loading
            title_text:'',//标题
            portrait:'',//封面照片
            portrait_file:'',//封面照片文件流
            form: {
                name: '',
                jobTitle: '',
                subject: '',
                introduction: '',
                sequence: ''
            },
            finish: false
        }
    },
    components:{
        QuilleditorWidget,
        UeditorWrap
    },
    mounted () {
        this.getTableData();
    },
    methods: {
        showAdd() {
            this.finish = true
            this.dialogTeacher = true
        },
        //获取表格数据
        async getTableData() {
            const { data: res } = await this.$http.get('/famousTeachers/list', {params:{type:'1'}})
            if (res.code !== 200) {
                this.$message.error(res.message)
            } else {
                this.tableData = res.result
            }
        },
        //上传封面照片
        handleChange({raw}){
            let types = ['image/jpeg','image/png','image/jpg'],maxSize = 3 * 1024 * 1024;;
            if(!types.includes(raw.type)){
                return this.$message.error('请上传正确格式的文件，目前支持jpg,png！');
            }
            if(raw.size > maxSize) {
                return this.$message.error('图片不允许超过3M！');
            }
            let src = webkitURL.createObjectURL(raw);
            this.portrait = src;
            this.portrait_file = raw;
        },
        //编辑
        async editItems(rows){
            /* const { data: res } = await this.$http.get('/schoolTeachers/detail', {params:{id:row.id}});
             if (res.code !== 200) {
                 this.$message.error(res.message)
             } else {
                 this.isEdit = true;
                 /!*this.title_text = res.result.title;
                 this.portrait = res.result.cover;
                 this.info = res.result;*!/

             }*/
            this.isEdit = true;
            this.$nextTick(() => {
                this.form.id = rows.id;
                this.form.name = rows.name;
                this.form.jobTitle = rows.jobTitle;
                this.form.subject = rows.subject;
                this.form.introduction = rows.introduction;
                this.form.sequence = rows.sequence;
                this.portrait = rows.cover;
                this.finish = true
            })
            this.dialogTeacher = true;
        },
        //关闭添加教研成果弹窗
        closedialogTeacher(){
            this.isEdit = false;
            this.btnFormLoading = false;
            this.finish = false
            this.portrait = '';
            this.portrait_file = '';
            this.form = {
                name: '',
                jobTitle: '',
                subject: '',
                introduction: '',
                sequence: ''
            }
        },
        //保存
        async submitForm(){
            if(this.form.name === ''){
                return this.$message.error('请输入姓名')
            }
            if(this.portrait == ''){
                return this.$message.error('请上传封面照片')
            }
            if(this.form.jobTitle === ''){
                return this.$message.error('请输入职称')
            }
            if(this.form.subject === ''){
                return this.$message.error('请输入学科')
            }
            // if(this.form.introduction === ''){
            //   return this.$message.error('请输入简介')
            // }
            if(this.$refs.quillView.content == ''){
                return this.$message.error('请输入简介')
            }
            if(this.form.sequence === ''){
                return this.$message.error('请输入显示顺序')
            }
            this.btnFormLoading = true;
            let result = null,formData = new FormData();
            formData.append('name', this.form.name);
            formData.append('file', this.portrait_file);
            formData.append('jobTitle', this.form.jobTitle);
            formData.append('subject', this.form.subject);
            // formData.append('introduction', this.form.introduction);
            formData.append('introduction', this.$refs.quillView.content);
            formData.append('sequence', this.form.sequence);
            formData.append('type', '1');
            if(this.isEdit) {//编辑
                formData.append('id', this.form.id);
                result = await this.$http({
                    url: '/famousTeachers/edit',
                    data: formData,
                    headers:{
                        'Content-Type': "multipart/form-data; charset=UTF-8",
                    },
                    method:'post',
                    timeout: 120000
                });
                console.log('编辑：')
            }else {//添加
                result = await this.$http({
                    url: '/famousTeachers/add',
                    data: formData,
                    headers:{
                        'Content-Type': "multipart/form-data; charset=UTF-8",
                    },
                    method:'post',
                    timeout: 120000
                });
            }
            let res = result.data;
            if (res.code !== 200) {
                this.btnFormLoading = false;
                this.$message.error(res.message)
            } else {
                this.btnFormLoading = false;
                this.$message.success('操作成功');
                this.dialogTeacher = false;
                this.$refs.quillView.content = ''
                this.getTableData();
            }
        },
        //删除
        removeItem(row) {
            this.$confirm('此操作将永久删除该条数据', '确定要删除“'+row.name+'”吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                closeOnClickModal: false,
                center: true,
                showClose: false,
                customClass:'wrap_text',
                beforeClose: async (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        let {data: res} = await this.$http({
                            url: '/famousTeachers/del',
                            params: {id: row.id},
                            headers:{
                                'Content-Type':"application/x-www-form-urlencoded",
                            },
                            method:'delete'
                        });
                        if(res.code == 200){
                            done();
                            this.$message.success('操作成功！')
                            this.getTableData();
                        }else{
                            this.$message.error(res.msg)
                        }
                        instance.confirmButtonLoading = false;
                    } else {
                        done();
                    }
                }
            }).catch(() => {
                this.$message.info('已取消');
            });
        },
    }
}
</script>
<style scoped>
.flex-warp{display:flex;align-items:center;}
.red{color:red;margin-right:5px;}
.sub-view-container{margin-top:20px;}
.mg15 {margin-top: 15px;}
.text_label{display:inline-block;min-width:90px;text-align: right;}
.avatar-uploader img{width:100%;height:100%;object-fit: cover;}
.tip-box {font-size: 13px;}
.tip-box div:nth-child(1) {margin-top: 15px;}
.tip-box div:nth-child(2) {margin-top: 15px;}
.avatar-uploader .el-icon-plus {
    font-size: 28px;
    margin-top: 50px;
}
</style>
<style>
.avatar-uploader .el-upload {
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    width: 192px;
    height: 255px;
}
.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.avatar-uploader .el-upload:hover .avatar-uploader-icon{
    display:block;
}
.avatar-uploader-icon {
    font-size: 14px;
    color: #8c939d;
    text-align: center;
    padding-top:115px;
}
.wrap_text .el-message-box__title{
    word-wrap: break-word;
    word-break: break-all;
    align-items: start;
}
</style>
